import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        };

        const handleClick = (event) => {
            const target = event.target.closest('a'); // Find the closest link element
            if (target) {
                scrollToTop();
            }
        };

        scrollToTop();
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [pathname]);

    return null;
};

export default ScrollToTop;
