import { Link, useNavigate } from "react-router-dom";
import CancelImage from "../../../../assets/images/cancelimage.png";
import { useEffect, useState } from "react";
import logo from "../../../../assets/logo.png";

const StripeCancelPage = () => {
    const [seconds, setSeconds] = useState(15);
    const navigate = useNavigate();

    useEffect(() => {
        let interval = null;
        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            navigate("/"); // Redirect to home page
        }
        return () => clearInterval(interval);
    }, [ seconds, navigate]);

    return (
        <div className="position-relative">
            <div className="py-4 d-flex justify-content-center" style={{ backgroundColor: "#3151b4" }}>
                <img width={"80px"} height={"80px"} src={logo} alt="Logo-icon" />
            </div>
            <div className="container d-flex justify-content-center align-items-center text-center">
                <section id="success" className="my-0 p-5 w-100">
                    <div className="row">
                        <div className="d-flex justify-content-start">
                            <img className="" width="150px" height="150px" src={CancelImage} alt="success-icon" />
                        </div>
                        <div className="col-12 d-flex justify-content-center align-items-start flex-column px-4">
                            <h4 className="text-danger text-start">Payment Cancelled</h4>
                            <p className="text-start">Your payment has been cancelled successfully.</p>
                            <Link to="/" className="btn btn-outline-success border-0">Go to Home</Link>
                            <p className="mt-4 text-muted">Redirecting to home page in {seconds} seconds...</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default StripeCancelPage;
