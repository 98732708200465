import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    useMutation
} from '@tanstack/react-query'
import "../../css/signin.css";
import * as Yup from "yup";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { useFormik } from "formik";
import * as api from "../../services/index";
import toast from "react-hot-toast";
import { useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    new_password: Yup.string()
        .required('New Password is required')
        .min(8, 'New Password must be at least 8 characters')
        .matches(/[A-Z]/, 'New Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'New Password must contain at least one lowercase letter')
        .matches(/\d/, 'New Password must contain at least one number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'New Password must contain at least one special character'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Confirm Password must be same as New Password.')
        .required('Confirm Password is required')
});

const ResetPasswordForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchquery = new URLSearchParams(location.search);
    const email = searchquery.get("email");
    const token = searchquery.get("token");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { mutate, isLoading } = useMutation({
        mutationFn: (payload) => api.ResetPassword(payload),
        onSuccess: (data) => {
            if (data?.data?.status == 200) {
                toast.success(data?.data?.message)
                navigate("/account/signin")
            }
            else {
                toast.error(data?.data?.message)
            }
        },
        onError: (error) => {
            toast.error(error.response.data.message)
        }
    })

    const formik = useFormik({
        initialValues: {
            email: email,
            new_password: "",
            confirm_password: "",
            token: token
        },
        validateOnBlur: true,
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: (values) => {
            let payload = {
                email: values.email,
                token: values.token,
                password: values.new_password
            }
            mutate(payload)
        }
    })

    return (
        <form
            onSubmit={formik.handleSubmit}
            className="row"
        >
            <div className="col-md-12 mb-4">
                <label className="mb-2" ><FaEnvelope className="me-2" /> Email<span style={{ color: 'red' }}> *</span></label>
                <input
                    type="text"
                    className="form-control rounded-0"
                    placeholder="Email"
                    name="email"
                    readOnly
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}

                />
                {formik.touched.email && formik.errors.email ? (
                    <span className="error text-danger error-msg mt-0">{formik.errors.email}</span>
                ) : null}
            </div>
            <div className="col-md-12 mb-4">
                <label className="mb-2" ><FaLock className="me-2" />New Password<span style={{ color: 'red' }}> *</span></label>
                <div className="position-relative">
                    <input
                        type={showNewPassword ? "text" : "password"}
                        className="form-control rounded-0"
                        placeholder="New Password"
                        name="new_password"
                        value={formik.values.new_password}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}

                    />
                    <div className="position-absolute top-0 h-100 eye-icon">
                        {!showNewPassword ? <span onClick={() => setShowNewPassword(true)} ><IoEyeOutline /></span> : <span onClick={() => setShowNewPassword(false)} ><IoEyeOffOutline /></span>}
                    </div>
                </div>
                {formik.touched.new_password && formik.errors.new_password ? (
                    <span className="error text-danger error-msg mt-0">{formik.errors.new_password}</span>
                ) : null}
            </div>
            <div className="col-md-12 mb-4">
                <label className="mb-2" ><FaLock className="me-2" />Confirm Password<span style={{ color: 'red' }}> *</span></label>
                <div className="position-relative">
                    <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control rounded-0"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        value={formik.values.confirm_password}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}

                    />
                    <div className="position-absolute top-0 h-100 eye-icon">
                        {!showConfirmPassword ? <span onClick={() => setShowConfirmPassword(true)} style={{ cursor: "pointer" }} ><IoEyeOutline /></span> : <span style={{ cursor: "pointer" }} onClick={() => setShowConfirmPassword(false)} ><IoEyeOffOutline /></span>}
                    </div>
                </div>
                {formik.touched.confirm_password && formik.errors.confirm_password ? (
                    <span className="error text-danger error-msg mt-0">{formik.errors.confirm_password}</span>
                ) : null}
            </div>
            <div className="col-md-12">
                <button
                    type="submit"
                    className="btn mb-3 text-light w-100 border-0"
                    disabled={isLoading}
                >
                    Reset Password
                </button>
            </div>
        </form>
    );
};

export default ResetPasswordForm;
