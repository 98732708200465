const Shipping = () => {
    return <div>
        <div className="headingBox">
            <div className="container">
                <div className="mainHeading">
                    <article className="mainHeading__text">
                        <p className="mainHeading__preTitle">LPI</p>
                        <h2 className="mainHeading__title">Shipping & Returns</h2>
                    </article>
                </div>
            </div>
        </div>

        <div className="container my-5 px-5">
            <p><strong>1. DELIVERY & SHIPPING POLICY</strong></p>
            <p>We can ship books anywhere in Malaysia or Internationally. When you place an order for a physical book we will estimate delivery dates based upon your shipment’s destination and contact you via email.</p>
            <p>Ebooks will be sent directly to your email address within 3 working days.</p>
            <p>FREE shipping to anywhere in Malaysia and your order will arrive within 5 working days.</p>
            <p>International orders will receive an email with the shipping quotation and estimated delivery time for your confirmation before proceeding. This will be billed through email and your order will ship immediately upon payment. On average, it will take 14 – 21 days to arrive at most international destinations.</p>
            <p>You may also email us your order directly at leonardpersonality@gmail.com</p>
            <p>In the event of any unsuccessful delivery of the goods due to unattended receiver at the time of delivery, or inaccurate and incomplete address, additional delivery charges may apply.</p>
            <p>LEONARD PERSONALITY INVENTORY  is not responsible for shipping charges of returned packages due to missing or wrong information.</p>
            <p><strong>2. REFUND & RETURN POLICY</strong></p>
            <p>Return Policy<br />
                Ebooks, the Leonard Personality Inventory (LPI) and Career test are non-refundable. Any claims of incorrect shipment, faulty or damaged goods on delivery, you can return your items for a prompt refund or exchange. Refunds for items returned within 5 days of the purchase date will be credited in the full amount and the same form as the original payment type.</p>
            <p>Delivery and packaging charges are not refundable unless as a result of our error. You will not be required for the extra shipping charges for incorrect item/s being shipped.</p>
            <p>Availability & Substitutions<br />
                If for any reason some goods are out of stock, or any reason beyond our reasonable control, our Customer Service will contact you and inform that if we have similar product that we can substitute or being delay in the delivery process until goods out of stock is being delivered to warehouse.</p>
            <p>Cancellation policy<br />
                If you would like to cancel your order, you can do so provided that the order has not yet been shipped. If the item you want to cancel has been shipped, the order cannot be cancelled.</p>
            <p>
                This website is owned by<br />
                LEONARD Personality Inventory<br />
                PO Box 199, Jalan Klang Lama<br />
                58700 Kuala Lumpur, Malaysia.<br />
                Tel: +60126587674<br />
                Email: leonardpersonality@gmail.com<br />
                Website: www.leonardpersonality.com<br />
            </p>
            <p>BUSINESS HOURS</p>
            <p>Our working hours are between 10 am to 6 pm Monday to Friday (Except for public holidays).</p>
        </div>

    </div>
}

export default Shipping;