import React from 'react';
import { RiMapPinLine, RiMailSendLine, RiPhoneLine } from "react-icons/ri";

const Contact = () => {

  const style = {
    fontWeight: '500'
  }

  return (
    <div>
      <div className="headingBox">
        <div className="container">
          <div className="mainHeading">
            <article className="mainHeading__text">
              <p className="mainHeading__preTitle">Contact</p>
              <h2 className="mainHeading__title">Get In Touch</h2>
            </article>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="container sec-contact">
          <div className="row mb-4">
            <div className="col-sm-12 mb-5">
              <div className='main-title'>Call now or write a <span>message</span></div>
            </div>
            <div className="col-md-6">
              <div className="contact-card">
                <div className="contact-icon">
                  <RiMapPinLine />
                </div>
                <div className="contact-commen-text">
                  <h6 className='sub-title'>Address</h6>
                  <p>
                    <span className='sub-title' style={style}>LEONARD Personality Inventory</span>
                    <br />
                    <span>Mailing Address : </span>
                    <span>
                      PO Box 199, Jalan Klang Lama, 58700 Kuala Lumpur, Malaysia.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="contact-card">
                <div className="contact-icon">
                  <RiPhoneLine />
                </div>
                <div className="contact-commen-text">
                  <h6 className='sub-title'>Phone</h6>
                  <p>
                    <a href='tel:+60126587674'><span className='sub-title' style={style}>+6012 658 7674</span></a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="contact-card">
                <div className="contact-icon">
                  <RiMailSendLine />
                </div>
                <div className="contact-commen-text">
                  <h6 className='sub-title'>Email</h6>
                  <p className='text-wrap text-break'>
                    <a href='mailto:leonardpersonality@gmail.com'><span className='sub-title' style={style}>leonardpersonality@gmail.com</span></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
                        <div className="col-sm-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.98263876876!2d101.67358511408953!3d3.0992728543231634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4a3bb6d9414d%3A0xa29d9886154741fb!2s199%2C%20Jln%20Klang%20Lama%2C%2058100%20Kuala%20Lumpur%2C%20Wilayah%20Persekutuan%20Kuala%20Lumpur%2C%20Malaysia!5e0!3m2!1sen!2sin!4v1642841253715!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" title="Leonard Perosality Inventory"></iframe>
                        </div>
                    </div> */}
        </div>
      </div>
    </div >
  )
}

export default Contact