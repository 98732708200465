import ResetPasswordForm from "../../components/account/ResetPasswordForm";

const ResetPassword = () => {
    return (
        <div className="container py-5 my-5">
            <div className="row ">
                <div className="signin-form m-auto" id="signup">
                    <div className="col-10 col-sm-8 col-md-5 signup-card m-auto">
                        <h4 className="text-center">Reset Password</h4>
                        <ResetPasswordForm />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;