import { useQuery } from "@tanstack/react-query";
import * as api from "../../services/index";
import { Link, useParams } from "react-router-dom";
import "./orderdetails.css";

export const OrderDetails = () => {
    const { order_txn_id } = useParams();
    const { isLoading, error, data } = useQuery({
        queryKey: ['orderdetails'],
        queryFn: () => api.orderDetails(order_txn_id)
    })

    if (error) {
        console.log("error:", error)
    }

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "70vh" }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }


    return (
        <div id="orderDetails" className="container">
            <div className="m-2 my-4 m-md-5 border border-danger p-2 p-md-5 position-relative orderDetails-card">
                <Link to="/profile?tab=Orders" className="position-absolute product-details-back-btn">Back</Link>
                <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="mb-4 order-detail-bold-color">Order Details</h3>
                </div>
                <div className="px-md-5 ">
                    <div className="row">
                        <div className="col-md-4">
                            <p className="mb-0 fw-semibold order-detail-bold-color">CUSTOMER DETAILS:</p>
                            <p className="mb-0">{data?.data?.data?.order_customer_name}</p>
                            <p className="mb-0">{data?.data?.data?.order_customer_phone}</p>
                            <p className="mb-0">{data?.data?.data?.order_customer_email}</p>
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="mt-3 mt-md-0">
                                <p className="fw-semibold order-detail-bold-color m-0">ORDER NO:</p>
                                <p className="">{data?.data?.data?.order_txn_id}</p>
                            </div>
                            <div>
                                <p className="fw-semibold order-detail-bold-color m-0">ORDER DATE:</p>
                                <p className="">{data?.data?.data?.order_txn_date}</p>
                            </div>
                            {/* <div>
                            <p className="fw-semibold float-start order-detail-bold-color m-0">ORDER DATE: {data?.data?.data?.order_txn_date}</p>
                        </div> */}
                        </div>
                    </div>
                    <div className="row my-3 my-md-4">
                        <div className="col-md-4">
                            <p className="mb-0 fw-semibold order-detail-bold-color">SHIPPING ADDRESS:</p>
                            <p className="mb-0">{data?.data?.data?.order_shipping_address?.address_line_one}</p>
                            <p className="mb-0">{data?.data?.data?.order_shipping_address?.state}, {data?.data?.data?.order_shipping_address?.country}</p>
                            <p className="">{data?.data?.data?.order_shipping_address?.postal_code}</p>
                        </div>
                        <div className="col-md-4">
                            <p className="mb-0 fw-semibold order-detail-bold-color">Tax Details:</p>
                            <p className="mb-0">{data?.data?.data?.order_tax_details?.company_name}, {data?.data?.data?.order_tax_details?.company_phone}</p>
                            {data?.data?.data?.order_tax_details?.company_registration_number && data?.data?.data?.order_tax_details?.company_registration_number !== "" && <p className="mb-0">Registration No. {data?.data?.data?.order_tax_details?.company_registration_number}</p>}
                            {data?.data?.data?.order_tax_details?.sst_number && data?.data?.data?.order_tax_details?.sst_number !== "" && <p className="mb-0">SST No. {data?.data?.data?.order_tax_details?.sst_number}</p>}
                            {data?.data?.data?.order_tax_details?.tin_number && data?.data?.data?.order_tax_details?.tin_number !== "" && <p className="">TIN No. {data?.data?.data?.order_tax_details?.tin_number}</p>}
                        </div>
                        <div className="col-md-4">
                            <p className="mb-0 fw-semibold order-detail-bold-color">BILLING ADDRESS:</p>
                            <p className="mb-0">{data?.data?.data?.order_billing_address?.address_line_one}</p>
                            <p className="mb-0">{data?.data?.data?.order_billing_address?.state}, {data?.data?.data?.order_billing_address?.country}</p>
                            <p className="">{data?.data?.data?.order_billing_address?.postal_code}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-sm-12 overflow-x-auto">
                                    <table className='ot-table'>
                                        <thead>
                                            <tr>
                                                <th className="text-start">Product Name</th>
                                                <th className="text-center">quantity</th>
                                                <th className="text-center">product type</th>
                                                <th className="text-center">price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.data?.data?.items?.length > 0 ? (data?.data?.data?.items?.map((el, i) => {
                                                return <tr key={i}>
                                                    <td className="text-start text-decoration-none"><img width={30} height={30} className="me-2" src={`${process.env.REACT_APP_PHOTO_URL}${el?.product?.product_thumbnail}`} alt="product-image" /> {el?.product?.product_name}</td>
                                                    <td className="text-center">{el?.quantity}</td>
                                                    <td className="text-center">{el?.product?.product_type}</td>
                                                    <td className="text-center">{el?.product?.product_price}</td>
                                                </tr>
                                            })) :
                                                (<div className="text-center w-100">
                                                    <p>No data found</p>
                                                </div>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-4 border-top py-3">
                        <div className="col-12">
                            <div className="row" >
                                <div className="col-6 col-md-3"></div>
                                <div className="col-6 col-md-3"></div>
                                <div className="col-6 col-md-3 text-start py-2">Sub Total</div>
                                <div className="col-6 col-md-3 text-end py-2 fw-semibold order-detail-bold-color">RM {data?.data?.data?.order_sub_total_amount}</div>
                                <div className="col-6 col-md-3"></div>
                                <div className="col-6 col-md-3"></div>
                                <div className="col-6 col-md-3 text-start py-2">Discount</div>
                                <div className="col-6 col-md-3 text-end py-2 fw-semibold order-detail-bold-color">{data?.data?.data?.order_discount_value}%</div>
                                <div className="col-6 col-md-3"></div>
                                <div className="col-6 col-md-3"></div>
                                <div className="col-6 col-md-3 text-start py-2">Discount Amount</div>
                                <div className="col-6 col-md-3 text-end py-2 fw-semibold order-detail-bold-color">RM {data?.data?.data?.order_discount_amount}</div>
                                <div className="col-6 col-md-3"></div>
                                <div className="col-6 col-md-3"></div>
                                <div className="col-6 col-md-3 text-start py-2">Shipping Charges</div>
                                <div className="col-6 col-md-3 text-end py-2 fw-semibold order-detail-bold-color">RM {data?.data?.data?.order_shipping_amount}</div>
                            </div>
                            <div className="row border-top py-3">
                                <div className="col-6 col-md-3"></div>
                                <div className="col-6 col-md-3"></div>
                                <div className="col-6 col-md-3 text-start">Total Amount</div>
                                <div className="col-6 col-md-3 text-end fw-semibold order-detail-bold-color">RM {data?.data?.data?.order_total_amount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetails;