import React from "react";
import "../css/staticpage.css";
import { getTrainers } from "../services/index";

const Trainers = () => {
  const [loading, setLoading] = React.useState(true);
  const [trainers, setTrainers] = React.useState([])

  const loadTrainers = async () => {
    try {
      const response = await getTrainers();
      console.log(response);
      if (response.status === 200) {
        setTrainers(response.data);
        setLoading(false);
      }
    } catch (error) {
      setTrainers([])
      setLoading(false);
    }
  }

  React.useEffect(() => {
    loadTrainers();
  }, [])

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh", backgroundColor: "#f8f9fa" }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="headingBox">
        <div className="container">
          <div className="mainHeading">
            <article className="mainHeading__text">
              <p className="mainHeading__preTitle">list of</p>
              <h2 className="mainHeading__title">Licensed LPI trainers</h2>
            </article>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <div className="st-box overflow-x-auto">
              <table className="st-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Certification</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    trainers.length > 0 ? (
                      trainers.map((trainer, index) => (
                        <tr key={index}>
                          <td>{trainer.trainer_number}</td>
                          <td>{trainer.trainer_name}</td>
                          <td>{trainer.trainer_certification}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No trainers found</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trainers;
