import React from "react";
import ReactDOM from "react-dom/client";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import App from "./App";
import { toast, ToastBar, Toaster } from 'react-hot-toast';
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";
import { loadState, saveState } from "./localStorage";
const persistedState = loadState();
const store = createStore(rootReducer, persistedState);

const queryClient = new QueryClient();

store.subscribe(() => {
  saveState(store);
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{
          top: 10,
          left: 0,
          width: '100%'
        }}
        toastOptions={{
          className: '',
          duration: 3000,
          removeDelay: 1000,
          style: { 
            color: '#363636',
            fontSize: '12px',
            width: '100%'
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <div className="d-flex align-items-center flex-row" onClick={() => toast.dismiss(t.id)}>
                {icon}
                {message}
              </div>
            )}
          </ToastBar>
        )}
      </Toaster>

    </Provider>
  </QueryClientProvider >
);

