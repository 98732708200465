import { useEffect, useState } from "react";
import FailedImage from "../../../../assets/images/failed-mark.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../../assets/logo.png";

export const StripeFailedPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [seconds, setSeconds] = useState(15);
    const navigate = useNavigate();
    useEffect(() => {
        let interval = null;
        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            navigate('/'); // Redirect to another page
        }
        return () => clearInterval(interval);
    }, [seconds, navigate]);

    return (
        <div className="position-relative">
            <div className="py-4 d-flex justify-content-center" style={{ backgroundColor: "#3151b4" }}>
                <img width={"80px"} height={"80px"} src={logo} alt="Logo-icon" />
            </div>
            <div className="container d-flex flex-column justify-content-center align-items-center text-center" id="st">
                <section id="failed" className="my-5 p-4">
                    <div className="row">
                        <div className="d-flex mb-4 justify-content-start">
                            <img className="" width="150px" height="150px" src={FailedImage} alt="success-icon" />
                        </div>
                        <div className="col-12 d-flex justify-content-center align-items-start flex-column px-4">
                            <h4 className="text-danger text-start">Oops... Payment Failed!</h4>
                            <p className="text-start">An email with your login information is headed your way.</p>
                            <p className="text-start">Sometimes this email can end up in your junk mail/SPAM folder or under “Promotions”. If you don’t see it within a few minutes, please search for the words noreply@leonardpersonality.com or email us and we’ll resend it. </p>
                            <p className="text-start">So if you need help with anything, please don’t hesitate to email us at leonardpersonality@gmail.com</p>
                            <Link to="/" className="btn btn-outline-success border-0">Go to Home</Link>
                            <p className="mt-4 text-muted">Redirecting to home page in {seconds} seconds...</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}